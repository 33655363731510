import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../Store/AppContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

function Apps({appID}) {

    const location = useLocation();
    // let appID = appID;
    const {data} = useContext(AppContext);
    const [appObj, setAppObj] = useState(null);

    

    useEffect(() => {
      const getApp = () => {
          data.forEach(app => {
              if (app.id === appID) {
                  setAppObj(app); // Update appObj state with the found app
              }
          });
      };

      getApp();
  }, [data]);

    if(!appObj){
      return(
        <h2>Loading...</h2>
      )
    }

    const text = appObj.details;

    const sentences = text.split(/\n|\r\n/);

    // Join the sentences/phrases with line breaks
    const textWithLineBreaks = sentences.map((sentence, index) => (
      <React.Fragment key={index}>
        {sentence}
        <br />
      </React.Fragment>
    ));

    console.log(textWithLineBreaks);

  return (
    <div className='appInfoDiv'>
      <Row className='my-4 text-center'>
        <h2>{appObj.name}</h2>
      </Row>
      <Row className='text-center my-2'>
        <Col><img src={appObj.img} className='appImg'></img></Col>
        <Col>
          <Row><a href={appObj && appObj.iphone}><img className='downloadImg' src='https://salfordcommunityleisure.co.uk/wp-content/uploads/2021/08/App-Store.png'></img></a></Row>
          <Row><a href={appObj && appObj.android}><img className='downloadImg' src='https://www.studiosity.com/hs-fs/hubfs/Android%20app%20badge%202022.png?width=6138&name=Android%20app%20badge%202022.png'></img></a></Row>
        </Col>
      </Row>
      <Row className='text-left border-top my-2 mx-2'>
          <p>{textWithLineBreaks}</p>
      </Row>
    </div>
  )
}

export default Apps