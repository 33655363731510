import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'reactstrap'
import axios from 'axios';
import { URLContext } from '../../../Store/URLContext';

function SubmitEdit({ taskID, formData, originalData}) {

    const {url} = useContext(URLContext);
    const navigate = useNavigate();

    const showFormData = async (e) => { //Check if we're adding a new object or editing one based on the presence of an id.
        e.preventDefault();
        if(taskID){
          await axios.put(`${url}/${taskID}`, formData);
      }else{
          await axios.post(`${url}`, formData);
      }
        navigate("/")
      }

  return (
    <div className='col'>
        <Button className='bg-primary w-100 subBtn' onClick={showFormData}>Submit</Button>
    </div>
  )
}

export default SubmitEdit