import React, { useState } from 'react'
import { Form, useNavigate } from 'react-router-dom';
import SubmitEdit from './SubmitEdit';
import { Col, Row } from 'reactstrap';

function AddApp() {

    const navigate = useNavigate();

    const [formData, setFormData] = useState({});

    const handleChange = (e) => {
        let prop = e.target.name;
        let value = e.target.value;
        setFormData(prev => ({...prev, [prop]: value}))
    }

  return (
    <div className='my-5'>
        <Row className='my-2'>
            <Col><input type='text' placeholder='App Name' name='name' onChange={handleChange}/></Col>
            <Col><input type='link' placeholder='Link to App Image' name='img' onChange={handleChange}/></Col>
        </Row>
        <Row className='my-2'>
            <input type='text' placeholder='Summary' name='summary' onChange={handleChange}/>
        </Row>
        <Row className='my-2'>
            <textarea type='text' rows={5} placeholder='Details/Instructions' name='details' onChange={handleChange}/>
        </Row>
        <Row className='my-2'>
            <Col><input type='link' placeholder='Link to iPhone app' name='iphone' onChange={handleChange}/></Col>
            <Col><input type='link' placeholder='Link to Android app' name='android' onChange={handleChange}/></Col>
        </Row>

        <SubmitEdit formData={formData}/>
    </div>
  )
}

export default AddApp