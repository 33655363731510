import React, { useEffect, useState } from 'react'
import { Button, Card } from 'reactstrap'
import SubmitEdit from './SubmitEdit'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Login() {

    const [users, setUsers] = useState({});
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();

    const handleChange = (e) => {
        let prop = e.target.name;
        let value = e.target.value;
        setFormData(prev => ({...prev, [prop]: value}))
    }

    useEffect(() => {
        const getUsers = async () => {
            let data = await axios.get('https://663d4cb017145c4d8c3934bb.mockapi.io/user')
            setUsers(data.data);
        }
        getUsers();

    },[])


  return (
    <div className='appInfoDiv'>
        <Card className='my-5 p-3 w-100'>
            <h2>Login</h2>
            <label>Username</label>
            <input type='text' name='username' onChange={handleChange}/>
            <label>Password</label>
            <input type='password' name='password' onChange={handleChange}/>
            <Button className='my-3' color='primary' onClick={() => {
                const matchingUser = users.find(user => user.username === formData.username);

                if (matchingUser) {
                    if(matchingUser.password === formData.password){
                        navigate('/admin');
                    }else{
                        console.log("Invalid Password");
                    }
                } else {
                    console.log("Invalid Username");
                }


            }}>Login</Button>
        </Card>
    </div>
  )
}

export default Login