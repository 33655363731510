import axios from 'axios';
import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { URLContext } from '../../Store/URLContext';
import { AppContext } from '../../Store/AppContext';

function ShowApps({setAppId}) {

    const navigate = useNavigate();
    const location = useLocation();
    const {url} = useContext(URLContext);
    const {data} = useContext(AppContext);


    if(!data){
        return(<h2>Loading...</h2>)
    }

    const checkAdmin = () => {
        if(location.pathname === "/admin/deleteapp"){
            return(
                <h2 className='text-center'>Please select app to be deleted</h2>
            )
        }else if(location.pathname === "/admin/editapp"){
            return(
                <h2 className='text-center'>Please select an app to edit</h2>
            )
        }
        return null;
    }

    const showApps = data.map((app) => {
        return(
            <div className='my-4 px-1 py-2 appDiv rounded' onClick={async () => {
                if(location.pathname === "/admin/deleteapp"){
                    await axios.delete(`${url}/${app.id}`)
                    window.location.reload();
                }else if (location.pathname === "/admin/editapp"){
                    navigate(`/admin/editapp/${app.id}`)
                }else{
                    setAppId(app.id)
                    navigate(`/app/${app.id}`)
                }
            }} key={app.id}>
                <Row>
                    <Col xs="4" className='appImgCol'><img className='appImg' src={app.img}></img></Col>
                    <Col>
                        <Row><h3>{app.name}</h3></Row>
                        <Row><p>{app.summary}</p></Row>
                    </Col>
                </Row>
            
            
            </div>
        )
    })

    return(<div className='w-100'>
        {checkAdmin()}
        {showApps}
        </div>)
}

export default ShowApps