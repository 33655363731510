import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

function Admin() {

  const navigate = useNavigate();

  return (
    <div className='w-75'>
      <Row className='my-5'>

        <Col onClick={() => navigate('/admin/addapp')} className='adminCol'>
          <div className='adminCard'>
            <Row className='justify-content-center'><img className='adminImg' src='plusIcon.png'></img></Row>
            <Row className='text-center'><h2>Add App</h2></Row>
          </div>
        </Col>

        <Col onClick={() => navigate('/admin/editapp')} className='adminCol'>
          <div className='adminCard'>
            <Row className='justify-content-center'><img className='adminImg' src='editIcon.png'></img></Row>
            <Row className='text-center'><h2>Edit App</h2></Row>
          </div>
        </Col>

        <Col onClick={() => navigate('/admin/deleteapp')} className='adminCol'>
          <div className='adminCard'>
            <Row className='justify-content-center'><img className='adminImg' src='trashIcon.png'></img></Row>
            <Row className='text-center'><h2>Delete App</h2></Row>
          </div>
        </Col>

      </Row>
    </div>
  )
}

export default Admin