import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Col, Row } from 'reactstrap'

function HeaderBar() {
    const navigate = useNavigate();
  return (
    <div className='header w-100'>
      <Row className='my-1 justify-content-end'>
        <Button className='w-5 mx-1' color='secondary' onClick={() => navigate("/login")}>Login</Button>

      </Row>
      <Row>
          <Col className='d-flex justify-content-center my-3'><img className='headerLogo' onClick={() => navigate("/")} src='https://dh-prod-cdn.azureedge.net/assets/media/images/property/property-logo-suncadia-w-2023.svg'></img></Col>
      </Row>
      <Row>
          <Col className='d-flex justify-content-center my-3'><h2>Welcome to Suncadia!</h2></Col>
      </Row>
    </div>
  )
}

export default HeaderBar