import React, { useContext, useState } from 'react'
import ShowApps from '../ShowApps'
import { useParams } from 'react-router-dom'
import SubmitEdit from './SubmitEdit';
import { AppContext } from '../../../Store/AppContext';
import { Col, Row } from 'reactstrap';

function EditApp() {

    const {id} = useParams();
    const {data} = useContext(AppContext);
    const [formData, setFormData] = useState({});

    const app = data.find(app => app.id === id);

    const handleChange = (e) => {
        let prop = e.target.name;
        let value = e.target.value;
        setFormData(prev => ({...prev, [prop]: value}))
    }

    const checkLocation = () => {
        if(!id){
            return(<ShowApps/>)
        }else{
            return(
                <div className='my-5'>
                    <Row className='my-2'>
                        <Col>
                            <input type='text' defaultValue={app.name} placeholder='App Name' name='name' onChange={handleChange}/>
                            <input type='link' defaultValue={app.img} placeholder='App Image' name='img' onChange={handleChange}/>
                        </Col>
                    </Row>
                    <Row className='my-2'>
                        <input type='text' defaultValue={app.summary} placeholder='Summary' name='summary' onChange={handleChange}/>
                    </Row>
                    <Row className='my-2'>
                        <textarea type='text' rows={5} defaultValue={app.details} placeholder='Details/Instructions' name='details' onChange={handleChange}/>
                    </Row>
                    <Row className='my-2'>
                        <Col>
                            <input type='link' defaultValue={app.iphone} placeholder='Link to iPhone app' name='iphone' onChange={handleChange}/>
                            <input type='link' defaultValue={app.android} placeholder='Link to Android app' name='android' onChange={handleChange}/>
                        </Col>
                    </Row>

                    <SubmitEdit taskID={id} formData={formData} originalData={app}/>
                </div>
            )
        }

    }

  return (
    <div>{checkLocation()}</div>
  )
}

export default EditApp