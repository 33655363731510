import { Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderBar from './components/HeaderBar/HeaderBar';
import Home from './components/Pages/Home';
import Admin from './components/Pages/Admin/Admin';
import { AppContext, appList } from './Store/AppContext';
import { useContext, useEffect, useState } from 'react';
import Apps from './components/Pages/Apps';
import axios from 'axios';
import AddApp from './components/Pages/Admin/AddApp';
import EditApp from './components/Pages/Admin/EditApp';
import DeleteApp from './components/Pages/Admin/DeleteApp';
import { URLContext, urlCon } from './Store/URLContext';
import { AppIDCon, AppIDContext, AppIDProvider } from './Store/AppIDContext';
import Login from './components/Pages/Admin/Login';

function App() {

  const [data, setData] = useState(appList);
  const [url, setURL] = useState(urlCon)
  const [appID, setAppId] = useState(AppIDCon)

  useEffect(() => {
    const getApps = async () => {
    const apps = await axios.get(url)
    setData(apps.data);
  }

    getApps()
  },[])

  return (
    <AppContext.Provider value={{data, setData}}>
    <URLContext.Provider value={{url, setURL}}>
    <AppIDContext.Provider value={{appID, setAppId}}>
      <div className="App-header">
        
        <HeaderBar />

        <Routes>
          <Route path='/' element={<Home setAppId={setAppId}/>} />
          <Route path='/admin' element={<Admin/>}/>
          <Route path='/app/:id' element={<Apps appID={appID}/>}/>
          <Route path='/admin/addapp' element={<AddApp/>}/>
          <Route path='/admin/editapp' element={<EditApp/>}/>
          <Route path='/admin/deleteapp' element={<DeleteApp/>}/>
          <Route path='/admin/editapp/:id' element={<EditApp/>}/>
          <Route path='/login' element={<Login/>}/>
        </Routes>

      </div>
    </AppIDContext.Provider>
    </URLContext.Provider>
    </AppContext.Provider>
  );
}

export default App;
