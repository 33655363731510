import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { AppContext } from '../../Store/AppContext';
import { AppIDCon, AppIDContext } from '../../Store/AppIDContext';
import ShowApps from './ShowApps';

function Home({setAppId}) {

    const {data} = useContext(AppContext)
    const {setData} = useContext(AppContext)

    const navigate = useNavigate();

  return (
    <div className='w-100 text-center mt-3'>
        <Row>
            <Col><h3>Internal Apps</h3></Col>
        </Row>
        <Row className='justify-content-center'>
            <Col className='appInfoDiv'><ShowApps data={data} setAppId={setAppId}/></Col>
        </Row>
    </div>
  )
}

export default Home