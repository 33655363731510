import React, { useContext } from 'react'
import ShowApps from '../ShowApps'
import { AppContext } from '../../../Store/AppContext'

function DeleteApp() {

    const {data} = useContext(AppContext)
    
  return (
    <div>
        <ShowApps data={data}/>
        
    </div>
  )
}

export default DeleteApp